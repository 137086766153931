// import { Context } from '@nuxt/types'
import { actionTree, getAccessorType } from 'typed-vuex'

// Import all your submodules
// import { Context } from '@nuxt/types'
import * as notification from './notification'
import * as app from './app'

export const state = () => ({})

export const actions = actionTree(
  { state },
  {
    nuxtClientInit (_vuexContext, app) {
      setTimeout(async () => {
        try {
          await app.$accessor.app.loadConfig()

          if (app.$auth?.isLoggedIn()) {
            await app.$accessor.app.loadPermissions()
          }
        } catch (e: any) {
          console.error(e)

          return app.error({ statusCode: 500, message: e.message })
        }
      })
    }
  }
)

// This compiles to nothing and only serves to return the correct type of the accessor
export const accessorType = getAccessorType({
  modules: {
    // The key (submodule) needs to match the Nuxt namespace (e.g. ~/store/submodule.ts)
    notification,
    app
  }
})
