import { Plugin } from '@nuxt/types'

const axiosPlugin:Plugin = ({ $axios, $auth, $cookies, $notification, i18n, app }) => {
  const isRefreshing = false

  $axios.onRequest((config: any) => {
    if ($cookies.get('i18n_redirected')) {
      config.headers['X-Lang'] = `${$cookies.get('i18n_redirected') || null}`
    }

    if (!isRefreshing) {
      config.headers.Authorization = `Bearer ${$cookies.get('Token') || ''}`
    }
  })

  $axios.onResponseError(async (error: any) => {
    const code = parseInt(error.response && error.response.status)

    if (!code) {
      console.log('Ooops, something went wrong')
      $notification.show({
        type: 'error',
        message: i18n.tc('notification.500')
      })
    }

    if (error.response && error.response.data) {
      error.GlobalError = error.response.data.GlobalError
      error.FieldsError = error.response.data.FieldsError
    }

    switch (code) {
      case 400:
        if (error.GlobalError && !error.FieldsError) {
          console.log('error', error.response)
          $notification.show({
            type: 'error',
            message: error.GlobalError
          })
        }

        throw error
      case 429:
        if (error.GlobalError && !error.FieldsError) {
          console.log('error', error.response)
          $notification.show({
            type: 'error',
            message: error.GlobalError
          })
        }

        throw error
      case 403:
        await app.router!.replace(app.localePath('/'))
        break
      case 401:
        await $auth.logout()
        break
        // if (isRefreshing) {
        //   isRefreshing = false
        //   $auth.logout()
        //   break
        // }
        //
        // try {
        //   isRefreshing = true
        //   const response = await $axios.post(
        //     '/account/generate-token/', {
        //       RefreshToken: $cookies.get('RefreshToken') || 'invalid'
        //     })
        //
        //   console.log(11111)
        //
        //   if (response?.data) {
        //     $auth.setTokens(response.data)
        //     error.config.headers.Authorization = `Bearer ${$cookies.get('Token')}`
        //     await $axios(error.config)
        //       .then((resp) => {
        //         isRefreshing = false
        //
        //         return resp
        //       })
        //       .catch((e) => {
        //         isRefreshing = false
        //         $auth.logout()
        //         console.log('error', e.GlobalError || e)
        //         $notification.show({
        //           type: 'error',
        //           message: e.GlobalError || e
        //         })
        //       })
        //   } else {
        //     await $auth.logout()
        //     $notification.show({ type: 'error', message: `${i18n.tc('warning.401')}` })
        //   }
        // } catch (err: any) {
        //   isRefreshing = false
        //
        //   if (err.response && err.response.status !== 401) {
        //     return
        //   }
        //   await $auth.logout()
        //   $notification.show({ type: 'error', message: `${i18n.tc('warning.401')}` })
        // }
        // break
      case 404:
        $notification.show({
          type: 'error',
          message: '[Error: 404]: Seems like this resource has been moved.'
        })
        throw error
      default:
        $notification.show({
          type: 'error',
          message: 'Нещо се обърка. Опитваме се да го разрешим възможно най-скоро.'
        })
        throw error
    }
  })
}

export default axiosPlugin
