
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class AppLayout extends Vue {
  @Prop({ default: false }) readonly isLogin!: boolean

  drawer = this.$vuetify.breakpoint.mdAndUp

  rightDrawer = false
}
