import { Plugin } from '@nuxt/types'
import { UserPermissionsType } from '~/types/response/roles'

type AuthPayload = {
  Token: string,
  RefreshToken: string
  Email?: string,
  FirstName?: string
}

type IAuth = {
  isLoggedIn(): boolean,
  login(payload: AuthPayload): void,
  setTokens(payload: AuthPayload): void,
  clearLocalStorage(): void,
  logout(payload?: boolean): void,
  permissionRouteGuard(routeName: keyof UserPermissionsType): void
  loginRouteGuard(): void
}

const authPlugin:Plugin = ({ app, $cookies, $accessor }, inject) => {
  const auth:IAuth = {
    isLoggedIn () {
      return !!$cookies.get('Token')
    },
    login (payload: AuthPayload) {
      auth.clearLocalStorage()
      auth.setTokens(payload)
    },
    setTokens (tokens: AuthPayload) {
      const user = {
        Email: tokens.Email,
        FirstName: tokens.FirstName
      }

      $cookies.set('Token', tokens.Token)
      $cookies.set('RefreshToken', tokens.RefreshToken)
      $cookies.set('user', user)

      $accessor.app.SET_USER_INFO(user)
    },
    clearLocalStorage () {
      $cookies.remove('Token')
      $cookies.remove('RefreshToken')
      $cookies.remove('user')
    },
    logout (manual?:boolean) {
      auth.clearLocalStorage()
      $accessor.app.SET_USER_INFO(null)

      console.log(manual)

      app.router!.push(app.localePath('/login'))
      // if (manual) {
      //   app.router!.push(app.localePath('/login'))
      // } else {
      //   app.router!.push({
      //     path: app.localePath('/login'),
      //     query: {
      //       redirectUrl: `${window.location.pathname}${window.location.search}`
      //     }
      //   })
      // }
    },
    permissionRouteGuard (routeName: keyof UserPermissionsType) {
      // Needed timeout for Nuxt to hydrate global object
      const timeout = !$accessor.app.userPermissions[routeName] ? 2500 : 0

      setTimeout(() => {
        if (!routeName || !$accessor.app.userPermissions![routeName]?.includes('view')) {
          // app.router!.push(app.localePath('/'))
        }
      }, timeout)
    },
    loginRouteGuard () {
      if (this.isLoggedIn()) {
        // app.router!.push(app.localePath('/'))
      }
    }
  }
  inject('auth', auth)
}

declare module 'vue/types/vue' {
    // this.$auth inside Vue components
    interface Vue {
        $auth: IAuth
    }
}

declare module '@nuxt/types' {
    // nuxtContext.app.$auth inside asyncData, fetch, plugins, middleware, nuxtServerInit
    interface NuxtAppOptions {
        $auth: IAuth
    }
    // nuxtContext.$auth
    interface Context {
        $auth: IAuth
    }
}

export default authPlugin
