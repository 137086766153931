import { Plugin } from '@nuxt/types'
import Vue from 'vue'
import type { IValidate } from '~/types/validate'
const validators = require('vuelidate/lib/validators')

const ValidatePlugin:Plugin = ({ i18n }, inject) => {
  const validate:IValidate = {
    alpha (message) {
      return (v: any) => /^[\p{L}+ *]+$/gu.test(v) || message || i18n.t('validation.alpha')
    },
    required (message) {
      return (v: any) =>
        validators.required(v) || message || i18n.t('validation.required')
    },
    requiredIf (condition: any, message) {
      if (condition) {
        return (v:any) =>
          validators.required(v) || message || i18n.t('validation.required')
      }

      return true
    },
    email (message) {
      return (v: any) =>
        validators.email(v) || message || i18n.t('validation.email')
    },
    numeric (message) {
      return (v: any) =>
        validators.numeric(v) || message || i18n.t('validation.numeric')
    },
    maxLength (max, message) {
      return (v: any) =>
        validators.maxLength(max)(v) || message || i18n.t('validation.maxLengthOf', [max])
    },
    minLength (min, message) {
      return (v: any) =>
        validators.minLength(min)(v) || message || i18n.t('validation.minLengthOf', [min])
    },
    minValue (min, message) {
      return (v: any) =>
        validators.minValue(min)(v) || message || `${i18n.t('validation.minValue')} ${min}`
    },
    maxValue (min, message) {
      return (v: any) =>
        validators.maxValue(min)(v) || message || `${i18n.t('validation.maxValue')} ${min}`
    },
    repeatPassword (pass:string, message:string) {
      return (v: any) =>
        v === pass || message || i18n.t('validation.repeatPassword')
    },
    clearErrorMsg (errorsRefer, errorName) {
      return () => {
        errorsRefer[errorName] = []

        return true
      }
    },
    validRange (form, minFieldName, maxFieldName) {
      return () => {
        return (
          ((form?.[minFieldName] ?? 0) <= (form?.[maxFieldName] ?? 0) &&
                        !!String(form?.[minFieldName] ?? '').length ===
                        !!String(form?.[maxFieldName] ?? '').length) ||
                    i18n.t('validation.notValidRange')
        )
      }
    },
    triggerValidFieldByRefer (refs, referName) {
      Vue.nextTick(() => {
        refs?.[referName].validate?.(true)
      })
    },
    validPhone (message) {
      return (v: any) => {
        return !(/[^ \s+0-9]+/g.test(v)) || message || `${i18n.t('validation.valid_phone_number')}`
      }
    },
    isValidBulgarianPhoneNumber (message) {
      return (v: any) => {
        // Regular expression to match Bulgarian phone numbers
        const bulgarianPhoneRegex = /^(?:\+?359|0)?8[789]\d{7}$/

        // Check if the value matches the Bulgarian phone number pattern
        return bulgarianPhoneRegex.test(v) || message || `${i18n.t('validation.valid_phone_number')}`
      }
    }
  }
  inject('v', validate)
}

declare module 'vue/types/vue' {
  // this.$v inside Vue components
  interface Vue {
    $v: IValidate
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$v inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $v: IValidate
  }
  // nuxtContext.$v
  interface Context {
    $v: IValidate
  }
}

export default ValidatePlugin
