import { mutationTree, actionTree } from 'typed-vuex'

type NotificationPayload = {
  type: string
  message: string
}

export const state = () => ({
  notification: null as null | NotificationPayload
})

export const mutations = mutationTree(state, {
  SHOW_NOTIFICATION (state, notification) {
    state.notification = notification
  },
  HIDE_NOTIFICATION (state) {
    state.notification = null
  }
})

export const actions = actionTree({ state, mutations }, {
  show ({ commit }, payload: NotificationPayload) {
    commit('SHOW_NOTIFICATION', payload)
  },
  hide ({ commit }) {
    commit('HIDE_NOTIFICATION')
  }
})
