
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class DefaultLayout extends Vue {
  get isLoginPage () {
    const exceptionRoutes = ['login', 'forgot-password']
    let isExceptionRoute = false

    exceptionRoutes.forEach((el) => {
      if (this.$route.name?.includes(el)) {
        isExceptionRoute = true

        return false
      }
    })

    return isExceptionRoute
  }
}
