
import { mdiClose } from '@mdi/js'
import { Component, Vue } from 'nuxt-property-decorator'
// import CloseIcon from '~/assets/icons/close.svg'

@Component({
  // components: { CloseIcon }
})
export default class Notification extends Vue {
  icons = {
    mdiClose
  }

  show = false

  notification = {
    type: '',
    message: ''
  }

  beforeCreate () {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'notification/SHOW_NOTIFICATION') {
        this.notification = mutation.payload
        this.show = true
      }
      if (mutation.type === 'notification/HIDE_NOTIFICATION') {
        this.show = false
      }
    })
  }
}
