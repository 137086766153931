
import { Component, Vue, Model } from 'nuxt-property-decorator'

@Component
export default class RightNavigationDrawer extends Vue {
  @Model('input') rightDrawer!: boolean|null

  items = [
    { title: 'Dashboard', icon: 'mdi-view-dashboard' },
    { title: 'Photos', icon: 'mdi-image' },
    { title: 'About', icon: 'mdi-help-box' }
  ]

  reInitialize () {
    console.log('reinitialize')
  }
}

