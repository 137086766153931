// import colors from '~/node-modules/vuetify/lib/util/colors'

export default {
  primary: {
    base: '#1890FF',
    darken1: '#304156',
    darken2: '#17253D'
  //   darken3: '#22125F',
  //   darken4: '#130D2C',
  //   darken5: '#5A566B',
  //   lighten1: '#7857F1',
  //   lighten2: '#ACADF4',
  //   lighten3: '#C9CAF8',
  //   lighten4: '#D9DAFA',
  //   lighten5: '#ECEDFD',
  //   lighten6: '#ACAAB5'
  },
  secondary: {
    base: '#01A86D',
    lighten1: '#23A772',
    lighten2: '#34D399',
    lighten3: '#67E5B7',
    lighten4: '#A5F1D3',
    lighten5: '#DEF9EF',
    darken1: '#1F2D3D'
  //   darken2: '#0b5230',
  //   darken3: '#083720',
  //   darken4: '#041b10'
  },
  // accent: {
  //   base: '#FDFBF4',
  //   lighten5: '#FDFBF4'
  // },
  // error: {
  // base: '#D31D49'
  //   darken1: '#a9173a',
  //   lighten1: '#F90854'
  // },
  warning: {
    base: '#FF900D',
    darken1: '#E6820C',
    darken2: '#cc730a',
    lighten1: '#FFA63D',
    lighten12: '#ffbc6e'
  },
  success: {
    base: '#01A86D'
  //   lighten1: '#23A772',
  //   lighten2: '#34D399',
  //   lighten3: '#67E5B7',
  //   lighten4: '#A5F1D3',
  //   lighten5: '#DEF9EF',
  //   darken1: '#0F6E40',
  //   darken2: '#0b5230',
  //   darken3: '#083720',
  //   darken4: '#041b10'
  },
  grayscale: {
    base: '#EEF1F6',
    lighten1: '#87858B',
    darken1: '#BFCBD9',
    darken2: '#3E3D40',
    darken3: '#222223',
    darken4: '#0E0D12'
  },
  disabled: '#87858B'
}
