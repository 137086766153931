import { Plugin } from '@nuxt/types'
import type { IAlert } from '~/types/alert'

const notificationPlugin:Plugin = ({ $accessor }, inject) => {
  const notification:IAlert = {
    show ({ type, message }) {
      $accessor.notification.show({ type, message })
    },
    hide () {
      $accessor.notification.hide()
    }
  }

  inject('notification', notification)
}

declare module 'vue/types/vue' {
  // this.$notification inside Vue components
  interface Vue {
    $notification: IAlert
  }
}

declare module '@nuxt/types' {
  // nuxtContext.app.$notification inside asyncData, fetch, plugins, middleware, nuxtServerInit
  interface NuxtAppOptions {
    $notification: IAlert
  }
  // nuxtContext.$notification
  interface Context {
    $notification: IAlert
  }
}

export default notificationPlugin
